<template>
  <v-container fluid class="ma-0 pa-0" style="position: relative;">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          style="z-index: 300;"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Connect your wallet</v-toolbar-title> 
          <v-spacer></v-spacer>
        </v-toolbar>
        <!--https://cure-walletconnect.netlify.app/  http://localhost:3000/-->
        <iframe id="ifrm" src="https://cure-walletconnect.netlify.app/" width="100%" height="800px" allowtransparency = "true" frameBorder="0" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"></iframe>
      </v-card>
    </v-dialog>

    <v-card
      tile
      dark
      img="/img/black.png"
      min-height="600"
      id="header-card"
      style="padding: 50px 0;"
    >
      <v-container fill-height class="px-6">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6" class="text-center">
          <v-img src="/img/nft-header.png" aspect-ratio="1" class="grey lighten-2" width="100%" style="margin: auto; background-color: transparent !important;"></v-img>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <h2 class="font-weight-regular my-0 py-0" style="line-height: 1.5em;">RACE4ACURE - {{totalMinted}} of 10,000 MINTED</h2>
          <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.25em; line-height: .8em">
            NFT PROMOTION
          </h1>
          <v-divider class="mb-4"></v-divider>
          <h3><b>NOW WITH MORE WAYS TO WIN!!!</b></h3>
          <p>As the exciting NASCAR race season kicks off, and the project gears up for its amazing RaceForACure' campaign, we wanted to do something extra special. That’s why we are hosting the biggest NFT promotion in the history of crypto! Now you can potentially win huge prizes worth over $100,000 while also donating to the cause and owning a piece of CURE History!</p>
          <p class="mb-8">So, race to purchase one, or more, of our limited supply NFT tickets for a chance to win one our prestigious prizes listed below, all while supporting CURE’s fight against Pediatric Cancer. Each large draw will take place when a target has been met. <b>DRAWING EVERY 100 NFTs SOLD</b></p>
          <div class="text-center"><h3 class="mb-4">0.1 BNB PER NFT</h3>
          <v-btn
            color="primary"
            dark
            large
            @click="dialog = true"
            class="mx-4 my-2"
          >
            BUY WITH CRYPTO
          </v-btn>
          <v-btn
            color="secondary"
            dark
            large
            href="https://buy.stripe.com/cN2025gQjbvYfQI5km"
            class="mx-4 my-2"
          >
            BUY WITH CREDIT CARD
          </v-btn>
          <p class="caption text-left mt-8">Purchasing with a credit card will secure your entry into the promotion. The NFT will not be accessible in a traditional software wallet. You will recieve an email within 24 hours of your purchase confirming your ticket number.</p>

          
        </div>
        </v-col>
      </v-row>
    </v-container>
    </v-card>



    <v-card
      tile
      img="/img/light_background.png"
      min-height="150"
      id="logos"
      style="padding: 10px 0;"
    >
      <v-container class="d-flex px-6" fill-height style="min-height: 150px;">
        <v-row>
          <v-col cols="12" sm="12" class="full-height my-auto" >
            <!--<h4 class="font-weight-bold text-center text-uppercase">Entry Time Remaining</h4>
            <flip-countdown deadline="2022-9-11 00:00:00"></flip-countdown>-->
            <h4 class="font-weight-bold text-center text-uppercase">NFT PROMOTION ENDS IN</h4>
            <iframe src="https://www.powr.io/countdown-timer/u/f9ab2622_1644961484#platform=iframe" style="width:100%;" height="120px" frameborder="0"></iframe>
          </v-col>
        </v-row>
      </v-container>
    </v-card>



    <v-card
      tile
      dark
      img="/img/black.png"
      min-height="700"
      style="padding: 100px 0;"
    >
      <v-container fill-height style="" class="px-6">
      <v-row align="center"
      justify="center">
        <v-col
          cols="12"
          sm="6"
          class="pr-8"
        >
          <h2 class="font-weight-regular my-0 py-0" style="line-height: 1.5em;">0.1 BNB NFT</h2>
          <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.25em; line-height: .8em">
            NFT TOKENOMICS
          </h1>
          <ul class="my-10" style="list-style-type: none; font-size:1.25em;">
            <li>40% Prize Pool</li> 
            <li>20% Childhood Cancer Support</li>
            <li>40% CURE Liquidity</li>
          </ul>
          <p class="mt-4 mr-10">Upon the sale of an NFT, the total NFTs sold will increased after each TARGET is reached, we will draw the prizes in that target. You can win one prize in each target reached, up to 6 total prizes. The promotion ends on September 11th, 2022. A random, live, drawing will take place to announce the winners at each target.</p>
          <h3 class="mt-4 mr-10 font-weight-bold">DRAWING OF A PRIZE VALUED UP TO $650 WITH EVERY 100 NFTS SOLD.</h3>
        </v-col>



        <v-col
          cols="12"
          sm="6"
          align="center"
          justify="center"
          class="px-8"
        >
          <v-timeline  >
            <v-timeline-item color="grey">
              <v-card light>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      color="white"
                    >
                      <h4 class="text-center font-weight-bold" style="line-height:1.1em;">TARGET 0<br><span class="font-weight-regular">&#60;250 NFTs</span></h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item class="text-right grey--text" color="orange" large>
              <v-card light>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      color="white"
                    >
                      <h4 class="text-center font-weight-bold" style="line-height:1.1em;">TARGET 1<br><span class="font-weight-regular">250 NFTs</span></h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item class="text-right grey--text" color="grey">
              <v-card light>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      color="white"
                    >
                      <h4 class="text-center font-weight-bold" style="line-height:1.1em;">TARGET 2<br><span class="font-weight-regular">500 NFTs</span></h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item class="text-right grey--text" color="grey">
              <v-card light>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      color="white"
                    >
                      <h4 class="text-center font-weight-bold" style="line-height:1.1em;">TARGET 3<br><span class="font-weight-regular">750 NFTs</span></h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item class="text-left grey--text" color="grey">
              <v-card light>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      color="white"
                    >
                      <h4 class="text-center font-weight-bold" style="line-height:1.1em;">TARGET 4<br><span class="font-weight-regular">1,500 NFTs</span></h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item class="text-right grey--text" color="grey">
              <v-card light>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      color="white"
                    >
                      <h4 class="text-center" style="line-height:1.1em;">TARGET 5<br><span class="font-weight-regular">3,000 NFTs</span></h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item class="text-left grey--text" color="grey">
              <v-card light>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      color="white"
                    >
                      <h4 class="text-center font-weight-bold" style="line-height:1.1em;">TARGET 6<br><span class="font-weight-regular">7,500 NFTs</span></h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </v-container>
    </v-card>




    <v-card
      tile
      dark
      img="/img/light_background.png"
      min-height="150"
      id="logos"
      style="padding: 10px 0;"
    >
      <v-container class="d-flex px-6" fill-height style="min-height: 150px;">
        <v-row>
          <v-col cols="12" sm="4" class="full-height my-auto" >
            <a href="https://noahgragsonracing.com/" target="_blank">
              <v-img src="/img/05.png" aspect-ratio="1.75" class="grey lighten-2" width="250" style="margin: auto; background-color: transparent !important;">
              </v-img>
            </a>
          </v-col>
          <v-col cols="12" sm="4" class="full-height my-auto">
            <a href="https://www.curetoken.net/" target="_blank">
            <v-img src="/img/03.png" aspect-ratio="1.75" class="grey lighten-2" width="250" style="margin: auto; background-color: transparent !important;">
            </v-img>
            </a>
          </v-col>
          <v-col cols="12" sm="4" class="full-height my-auto">
            <a href="https://thebeckleyfoundation.org/" target="_blank">
            <v-img src="/img/04.png" aspect-ratio="1.75" class="grey lighten-2" width="250" style="margin: auto; background-color: transparent !important;">
            </v-img>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    


    <v-card
      tile
      
      img="/img/orange_back_01.png"
      min-height="700"
      style="padding: 100px 0;"
    >
      <v-container fill-height class="px-6">
        <v-row align="center"
        justify="center">
          <v-col
            cols="12"
            sm="6"
            align="center"
            justify="center"
          >
            <v-img src="/img/camaro-1ls.png" eager style="background-color: transparent !important;"></v-img>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <h2 class="font-weight-regular my-0 py-0" style="line-height: 1.5em;">TARGET 6 &mdash; <b>{{numPrizes[0]}} PRIZES</b></h2>
            <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.25em; line-height: .9em;">
              7,500 NFTs MINTED
            </h1>
            <p class="mb-10">
              CURE is the evolution of the Beckley Foundation, a 501c3 non-profit, charity organization dedicated to the eradication of childhood cancer through targeted research and support to affected families. The project is serious about its aims, so felt it only right that the prizes offered in return for pledged support through NFT purchases were equally serious. Below is a list of everything that can be won if you are lucky enough to mint a special NFT:  
            </p>
            <v-divider class="mb-4"></v-divider>
            <v-simple-table dense dark>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Prize
                    </th>
                    <th class="text-center">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in prizes[0]"
                    :key="item.prize"
                  >
                    <td>{{ item.prize }}</td>
                    <td class="text-center">{{ item.quantity }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>





    <v-card
      tile
      dark
      img="/img/grey_back_03.png"
      min-height="700"
      style="padding: 100px 0;"
    >
      <v-container fill-height class="px-6">
        <v-row align="center"
        justify="center">
          <v-col
            cols="12"
            sm="6"
            align="center"
            justify="center"
          >
            <v-img src="/img/macbook.png" eager style="background-color: transparent !important;"></v-img>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <h2 class="font-weight-regular my-0 py-0" style="line-height: 1.5em;">TARGET 5 &mdash; <b>{{numPrizes[1]}} PRIZES</b></h2>
            <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.25em; line-height: .9em;">
              3,000 NFTs MINTED
            </h1>
            <p class="mb-10">
              CURE is a 100% community driven project led by a passionate team of dedicated volunteers and partners who have the passion and drive to not only eradicate pediatric cancer, but to continue on to overhaul the entire global healthcare system as we know it, to provide a genuine positive change for everyone on the planet. The token was fair launched at $200,000 Market Cap to immense success and has since reached $56 million USD MC as its all-time high. 
            </p>
            <v-simple-table dense dark>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Prize
                    </th>
                    <th class="text-center">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in prizes[1]"
                    :key="item.prize"
                  >
                    <td>{{ item.prize }}</td>
                    <td class="text-center">{{ item.quantity }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>




    <v-card
      tile
      
      img="/img/orange_back_02.png"
      min-height="700"
      style="padding: 100px 0;"
    >
      <v-container fill-height class="px-6">
        <v-row align="center"
        justify="center">
          <v-col
            cols="12"
            sm="6"
            align="center"
            justify="center"
          >
            <v-img src="/img/ipad.png" eager style="background-color: transparent !important;"></v-img>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <h2 class="font-weight-regular my-0 py-0" style="line-height: 1.5em;">TARGET 4 &mdash; <b>{{numPrizes[2]}} PRIZES</b></h2>
            <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.25em; line-height: .9em;">
              1,500 NFTs MINTED
            </h1>
            <p class="mb-10">
              Through our mission to end childhood cancer and improve the health of the entire world CURE Token has partnered with the amazing NASCAR team Kaulig Racing and speed demon Noah Gragson in the NASCAR Cup Series at Hollywood Casino 400 to help raise awareness to the noble project.
            </p>
            <v-simple-table dense dark>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Prize
                    </th>
                    <th class="text-center">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in prizes[2]"
                    :key="item.prize"
                  >
                    <td>{{ item.prize }}</td>
                    <td class="text-center">{{ item.quantity }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            
          </v-col>
        </v-row>
      </v-container>
    </v-card>





    <v-card
      tile
      dark
      img="/img/grey_back_02.png"
      min-height="700"
      style="padding: 100px 0;"
    >
      <v-container fill-height class="px-6">
        <v-row align="center"
        justify="center">
          <v-col
            cols="12"
            sm="6"
            align="center"
            justify="center"
          >
            <v-img src="/img/ipad.png" eager style="background-color: transparent !important;"></v-img>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <h2 class="font-weight-regular my-0 py-0" style="line-height: 1.5em;">TARGET 3 &mdash; <b>{{numPrizes[3]}} PRIZES</b></h2>
            <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.25em; line-height: .9em;">
              750 NFTs MINTED
            </h1>
            <p class="mb-10">
              Our project is one with real utility, and is designed to attract long-term investors who believe in the powerful possibilities that emerge when bridging the innovative cryptocurrency space with causes focused on ending modern diseases, beginning with childhood cancer.
            </p>
            <v-simple-table dense dark>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Prize
                    </th>
                    <th class="text-center">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in prizes[3]"
                    :key="item.prize"
                  >
                    <td>{{ item.prize }}</td>
                    <td class="text-center">{{ item.quantity }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>




    <v-card
      tile
      
      img="/img/orange_back_02.png"
      min-height="700"
      style="padding: 100px 0;"
    >
      <v-container fill-height class="px-6">
        <v-row align="center"
        justify="center">
          <v-col
            cols="12"
            sm="6"
            align="center"
            justify="center"
          >
            <v-img src="/img/ipad.png" eager style="background-color: transparent !important;"></v-img>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <h2 class="font-weight-regular my-0 py-0" style="line-height: 1.5em;">TARGET 2 &mdash; <b>{{numPrizes[4]}} PRIZES</b></h2>
            <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.25em; line-height: .9em;">
              500 NFTs MINTED
            </h1>
            <p class="mb-10">
              Through our mission to end childhood cancer and improve the health of the entire world CURE Token has partnered with the amazing NASCAR team Kaulig Racing and speed demon Noah Gragson in the NASCAR Cup Series at Hollywood Casino 400 to help raise awareness to the noble project.
            </p>
            <v-simple-table dense dark>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Prize
                    </th>
                    <th class="text-center">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in prizes[4]"
                    :key="item.prize"
                  >
                    <td>{{ item.prize }}</td>
                    <td class="text-center">{{ item.quantity }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            
          </v-col>
        </v-row>
      </v-container>
    </v-card>





    <v-card
      tile
      dark
      img="/img/grey_back_02.png"
      min-height="700"
      style="padding: 100px 0;"
    >
      <v-container fill-height class="px-6">
        <v-row align="center"
        justify="center">
          <v-col
            cols="12"
            sm="6"
            align="center"
            justify="center"
          >
            <v-img src="/img/ps5.png" eager style="background-color: transparent !important;"></v-img>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <h2 class="font-weight-regular my-0 py-0" style="line-height: 1.5em;">TARGET 1 &mdash; <b>{{numPrizes[5]}} PRIZES</b></h2>
            <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.25em; line-height: .9em;">
              250 NFTs MINTED
            </h1>
            <p class="mb-10">
              Our project is one with real utility, and is designed to attract long-term investors who believe in the powerful possibilities that emerge when bridging the innovative cryptocurrency space with causes focused on ending modern diseases, beginning with childhood cancer.
            </p>
            <h2>WINNERS ANNOUNCED ON 3/1</h2>
            <p>
              If you won, please reach out to any of the admins on telegram to receive your prize by 3/15, or email support@curetoken.net! If the prizes are not claimed, they will be added to the next target.<br><br>
            </p>
            <ul>
              <b>NASCAR Tickets 🏎 — CLAIMED </b><br>
              NFT #72<br>
              Wallet Address: 0x7597...dadb9<br><br>
              
              <b>PS5 🎮 — AWARDED IN REDRAW ON 3/17</b><br>
              NFT #103<br><br>

              <b>iPhone Pro 13 📱 — AWARDED IN REDRAW ON 3/17</b><br>
              NFT #182<br><br><br>
            </ul>
            <v-simple-table dense dark>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Prize
                    </th>
                    <th class="text-center">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in prizes[5]"
                    :key="item.prize"
                  >
                    <td>{{ item.prize }}</td>
                    <td class="text-center">{{ item.quantity }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    

    
    <v-sheet
      color="grey darken-4"
    >
    <v-container class="px-6 white--text caption grey--text">
    <h2 class="mt-10">RaceForACure - NFT Giveaway Terms and Conditions:</h2>
    <h3 class="">This is not a sweepstakes or a raffle. This is an opportunity to reward holders of these 10,000 exclusive NFTs.</h3>
    <p class="mt-4">
      1. By entering the giveaway you are agreeing to these Giveaway terms and conditions.
      <br>
      2. The Giveaway is being run by CURE Token.
    </p>

    <h4 class="mt-4">Eligibility to enter, the responsibility lies upon the NFT owner</h4>
    <p>
      3. The giveaway is open to entrants over 18 years of age including team members of CURE Token and staff working within the ‘Race For A CURE’ campaign (Excl. Residents of the following U.S. States: Alabama, California, Hawaii, Kansas, Utah, Washington, Iowa, Minnesota, New Jersey, Montana (raffle ticket purchases by credit card are prohibited), Indiana.) In entering the giveaway you take responsibility in ensuring the allowance to ensure under your local jurisdiction's Laws. Eligibility to enter: the responsibility lies upon the NFT owner and CURE cannot be held accountable for underage purchases. 
      <br>
      4. In entering the giveaway, you confirm that you are eligible to do so and eligible to claim any prize you may win. If you are found to be underage when winning the prize, CURE reserves the right to refuse gifting the item.
      <br>
      5. The giveaway is marketed as being 0.1 BNB to enter, however the entry cost per NFT mint is subject to change if decided upon by the core team.
    </p>
    
    <h4 class="mt-4">How to enter</h4>
    <p>
      6. The giveaway will include all those who are currently members of our social media outlets and all new subscribers up to Midnight of 11th September 2022. Entries after that time and date will not be included in the draw.
      <br>
      7. To enter the giveaway simply mint an NFT from this landing page, there is no maximum entry amount per user or wallet.
      <br>
      8. CURE Token will not accept responsibility if you lose your seed phrase to the wallet where proof of NFT ownership via the blockchain will need to be provided.
    </p>

    <h4 class="mt-4">The prizes</h4>
    <p>
      9. The Prize draw will be made once each target is reached or all 10,000 NFT’s are minted or the deadline of midnight of 11th September 2022 is reached.
      <br>
      10. The prizes are subject to the total amount minted from the limited supply of 10,000 NFT’s. The prizes are clearly listed on the website from target 1 to target 6. Prizes are subject to availability and if unable to be delivered a cash equivalent will be offered.
      <br>
      11. CURE Token's use of particular brands as prizes does not imply any affiliation with or endorsement of such brands.
      <br>
      12. The winner’s will be drawn at random, and you will need to provide evidence of purchase via the blockchain and ownership over that wallet to claim rewards.
      <br>
      13. The prize is non-exchangeable, non-transferable and no cash alternatives will be offered unless as aforementioned the prize in unable to be delivered in a reasonable time frame due to availability in your region.
      <br>
      14. We reserve the right to substitute prizes with another prize of equal or higher value if circumstances beyond our control make it necessary to do so.
      <br>
      15. We reserve the right to monitor the value of the primary currency being used to purchase the NFT, in this case BNB (BEP-20) and either control prize pool total through the increase or decrease of minting fee or the reduction of prize pool total value.
      <br>
      16. The decision of CURE Token regarding any aspect of the giveaway is final and binding and no correspondence will be entered into about it.
    </p>

    <h4 class="mt-4">Winner announcement</h4>
    <p>
      17. The winner will be notified after 12th September 2022 via the email (if provided) as well as all social media outlets.
      <br>
      18. CURE Token will attempt to contact the winner by email if one is mentioned up to two times as well as listing it twice on social media.
      <br>
      19. If the winner does not respond to the public announcement notifying them of their win within 14 days of the second notice, they will lose their right to the prize, and CURE Token LLC reserves the right to choose and notify a new winner.
    </p>

    <h4 class="mt-4">Receipt of the prize</h4>
    <p>
      20. Please allow 14 days, or more, for delivery of the prize or alternative collection or delivery arrangements may be made through mutual agreement.
    </p>

    <h4 class="mt-4">Data protection and publicity</h4>
    <p>
      21. You consent to any personal information you provide in entering the giveaway being used by CURE Token for the purposes of administering the giveaway, and for those purposes of media affiliation.
      <br>
      22. All entrants may apply for details of the winning participant by contacting us at operations@curetoken.net
      <br>
      23. The winner agrees to the release of their social media handle tag and any images which will be provided in the aim to celebrate your win.
      <br>
      24. An announcement of the winner’s social media handle tag and photo will be made via CURE Token's social media outlets.
      <br>
      25. All personal information shall be used in accordance with CURE Token's limitation of liability.
      <br>
      26. CURE Token does not accept any liability for any damage, loss, injury, transportation costs, import or export taxes, future costs or disappointment suffered by any entrants as a result of either participating in the giveaway or being selected for a prize, save that CURE Token does not exclude its liability for death or personal injury as a result of its own negligence.
      <br>
      27. CURE Token does not provide any form of practical or IT support if relevant for this prize. On receipt, all responsibilities relating to warranty and the product are that of the prize winner.
    </p>

    <h4 class="mt-4">General</h4>
    <p>
      28. CURE Token reserves the right to cancel the giveaway or amend these terms and conditions at any time, without prior notice.
      <br>
      29. The giveaway and these terms and conditions will be governed by International law and any disputes will be subject to the exclusive jurisdiction of the courts of your relevant justice system.
    </p>
    </v-container>
    </v-sheet>
  

    
  </v-container>
</template>
<style>
  #myVideo {
    width: 100%;
    max-width: 350px;
  }

  h1{
    font-size: 3em !important;
  }
  .flip-card__top{
    color:#e14d28 !important;
  }

  .flip-card__bottom, .flip-card__back-bottom{
    color:#f48226 !important;
  }

  .flip-card__back-bottom{
    color:#f48226 !important;
  }

  .flip-card__back::before, .flip-card__back-bottom::after{
    color:#ff9f00 !important;
  }

  .flip-clock__slot{
    text-transform: uppercase;
    font-size: .8rem !important;
  }


  @keyframes metronome-example {
    from {
      transform: scale(.5);
    }

    to {
      transform: scale(1);
    }
  }

  .v-avatar--metronome {
    animation-name: metronome-example;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
</style>
<script>
  /*
  <meta property="og:title" content="Race For A CURE" />
  <meta property="og:description" content="Race For A CURE - CURE Token has sponsored Driver Noah Gragson of Kaulig Racing in the 2022 NASCAR CUP Series" />
  <meta property="og:url" content="https://race4acure.com" />
  <meta property="og:image" content="https://race4acure.com/img/Race-For-A-Cure.png" />
  */
  export default {
    name: 'Homepage',
    components: {  },
    methods: {
      get_total_minted() {
        console.log("get_total_minted");
        const Web3 = require('web3')
        const rpcURL = "https://bsc-dataseed1.binance.org:443"
        const web3 = new Web3(rpcURL)
        const abi = [{"inputs":[],"name":"totalSupply","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}]
        const c_address = "0xf753f64aac6d79ad73eb9763a4888f7bae557320"
        const contract = new web3.eth.Contract(abi, c_address)
        console.log(contract);
        contract.methods.totalSupply().call().then((result) => { this.totalMinted = result; });

      },
    },
    created() {
      console.log("created");
      this.get_total_minted();
    },
    computed: {
      
    },
    data: () => ({
      dialog: false,
      totalMinted: 0,
      bpm: 0,
      interval: null,
      isPlaying: false,
      numPrizes: [1,4,3,2,3,3],
      prizes: [[
          {
            prize: '2022 Camaro 1LS',
            quantity: 1,
          },
        ],
        [
          {
            prize: '16" MacBook Pro',
            quantity: 1,
          },
          {
            prize: '12.9" iPad Pro',
            quantity: 1,
          },
          {
            prize: 'Sony PS5',
            quantity: 1,
          },
          {
            prize: 'iPhone 13 Pro',
            quantity: 1,
          },
        ],
        [
          {
            prize: '16" MacBook Pro',
            quantity: 1,
          },
          {
            prize: '12.9" iPad Pro',
            quantity: 1,
          },
          {
            prize: 'iPhone 13 Pro',
            quantity: 1,
          },
        ],
        [
          {
            prize: '12.9" iPad Pro',
            quantity: 1,
          },
          {
            prize: 'iPhone 13 Pro',
            quantity: 1,
          },
        ],
        [
          {
            prize: '12.9" iPad Pro',
            quantity: 1,
          },
          {
            prize: 'Sony PS5',
            quantity: 1,
          },
          {
            prize: 'iPhone 13 Pro',
            quantity: 1,
          },
        ],
        [
          {
            prize: 'Sony PS5',
            quantity: 1,
          },
          {
            prize: 'iPhone 13 Pro',
            quantity: 1,
          },
          {
            prize: 'NASCAR Hollywood Casino 500 Kansas City Race Tickets',
            quantity: 1,
          },
        ]],
    }),
  }
</script>
