<template>
  <v-app>
    <v-app-bar
      app
      color="white"
    >
      <div class="d-flex align-center">
        <v-img
          alt="CURE Token Logo"
          class="shrink mr-2"
          contain
          src="/img/CURE-full-logo.png"
          transition="fade-transition"
          width="200"
        />
      </div>

      <v-spacer></v-spacer>
      <div v-if="$route.path=='/'">
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      
      <div v-if="!$vuetify.breakpoint.mobile">
        
        
      <v-btn
        text
        color="grey darken-3"
        @click="scrollMeTo('team')"
      >
        THE RACE TEAM
      </v-btn>
      <v-btn
        text
        color="grey darken-3"
        @click="scrollMeTo('cure')"
      >
        ABOUT CURE TOKEN
      </v-btn>
      <v-btn
        text
        color="grey darken-3"
        @click="scrollMeTo('foundation')"
      >
        THE FOUNDATION
      </v-btn>
      </div> 
    </div>



    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      top
      temporary
      app
    >
      <v-list
        nav
        
      >
        <v-list-item-group
          v-model="group"
          class="text-center mt-10"
        >
          
          <v-list-item @click="scrollMeTo('team')">
            <v-list-item-title>THE RACE TEAM</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollMeTo('cure')">
            <v-list-item-title>ABOUT CURE TOKEN</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollMeTo('foundation')">
            <v-list-item-title>THE FOUNDATION</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>



    <v-main>
      <router-view transition="fade-transition"></router-view>
    </v-main>

    <v-footer
      dark
      padless
      height="400px"
      color="grey darken-4"
    >
      <v-container class="mx-auto">
      <v-card
        flat
        tile
        class="white--text text-center"
        color="transparent"
      >
        <v-card-text>
          <v-btn
            v-for="social in socials"
            :key="social.icon"
            class="mx-4 white--text"
            icon
          >
            <a :href="social.link" target="_blank" style="text-decoration: none;">
              <v-icon size="24px">
                {{ social.icon }}
              </v-icon>
            </a>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          Our mission is to eradicate childhood cancer. CURE Token has partnered with the amazing NASCAR team Kaulig Racing and celebrated driver Noah Gragson in the NASCAR Cup Series at Hollywood Casino 400 to help raise awareness.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>CURE Token, LLC - The Beckley Foundation</strong>
        </v-card-text>
      </v-card>
    </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
  },
  watch: {
      group () {
        this.drawer = false
      },
    },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    scrollMeTo(refName) {
      console.log(refName)
      var element = document.getElementById(refName);
      console.log(element)
      var top = element.offsetTop;
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    }
  },
  data: () => ({
    socials: [
      {'icon':'mdi-facebook', 'link':'https://www.facebook.com/CureToken'},
      {'icon':'mdi-twitter', 'link':'https://twitter.com/CURE_Ecosystem'},
      {'icon':'mdi-instagram', 'link':'https://www.instagram.com/curetoken/'},
    ],
    drawer: false,
      group: null,
  }),
};
</script>
