import Vue from 'vue';
import Router from 'vue-router';
import Homepage from '../components/Homepage.vue';
import NFT from '../components/NFT.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Homepage',
      component: Homepage,
    },
    {
      path: '/nft',
      name: 'NFT',
      component: NFT,
    }
  ],
});
