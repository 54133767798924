<template>
  <v-container fluid class="ma-0 pa-0" style="position: relative;" >
    
    <v-dialog
      eager
      v-model="buyWidget"
      max-width="500"
      v-if="buyWidget"
    >
      <v-card fill-height >
        <iframe src="https://www.curepayapp.com/widget?clientID=6eae00be-b74e-4841-99fb-13a0d70ed737" id="widget" width="400" height="646" style="width:100%; display:block;" frameBorder="0" scrolling="no"></iframe>
      </v-card>
    </v-dialog>


    <v-dialog
      eager
      v-model="promotion"
      max-width="500"
      v-if="promotion"
    >
      <v-card >
        <div>
          <v-img src="/img/diecast.png" eager style="background-color: transparent !important;"></v-img>
        </div>
        <v-container >
          <v-row align="center"
          justify="center">
            <v-col
              cols="12"
              class="px-10 pt-10"
            >
              <h2 class="font-weight-thin my-0 py-0" style="line-height: 1em;">NASCAR FANS</h2>
              <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.5em; line-height: .8em;">
                LIMITED EDITION DIECAST
              </h1>

              <p class="">
                We're giving away 500 limited edition, exclusive, and collectible die-cast cars to the next donors that support pediatric cancer research. Donate $50 or more, and we'll take it from there.
              </p> 
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-row>
          <v-col
              cols="12"
            >
            <v-btn
                x-large
                block
                color="orange"
                class="white--text px-8"
                href="https://thebeckleyfoundation.org/"
                target="_blank"
              >Make a Donation</v-btn>
            </v-col>
              <v-col
              cols="12"
            >
              <v-btn
            color="grey"
            text
            block
            @click="promotion = false"
            class="mr-6"
          >
            MAYBE LATER
          </v-btn>
        </v-col>
        </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>


    <v-parallax
      dark
      src="/img/black.png"
      style="overflow: hidden;"
      height="900"
      id="header-parallax"
      class="pt-10"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-container style="height:800px;" class="">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <img
              src="/img/header-logo.png"
              style="
                width: 100%; max-width:500px;"
            />
            <h2 class="mt-4">Celebrate NASCAR with us for a chance to win tickets! Scroll down to find out more!</h2>
            <h4 class="subheading mt-8" style="max-width:500px;">
              Our mission is to eradicate childhood cancer. CURE Token has partnered with the amazing NASCAR team Kaulig Racing and celebrated driver Noah Gragson in the NASCAR Cup Series at Hollywood Casino 400 to help raise awareness.
            </h4>
            <!--<v-btn
              color="white"
              dark
              x-large
              @click="clickNFT"
              class="primary--text mt-8"
              style="z-index: 200;"
            >
              VIEW NFT SWEEPSTAKES
            </v-btn>-->
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <img
        src="/img/mobile-car.png"
        class="grey lighten-2"
        style="margin: 0px 0px 0px -75px; background-color: transparent !important; position: absolute;
        width: 500px;
      "/>
          </v-col>
          
        </v-row>
        
      </v-container>
      
    </v-parallax>
      
      

    <v-parallax
      dark
      src="/img/header_black.png"
      style="overflow: visible;"
      height="650"
      id="header-parallax"
      v-if="!$vuetify.breakpoint.smAndDown"
    >
      <v-container>
        
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <img
            src="/img/header-logo.png"
            style="
              width: 100%;"
          />
          <h2 class="mt-4">Celebrate NASCAR with us for a chance to win tickets! Scroll down to find out more!</h2>
          <h4 class="subheading mt-8">
            Our mission is to eradicate childhood cancer. CURE Token has partnered with the amazing NASCAR team Kaulig Racing and celebrated driver Noah Gragson in the NASCAR Cup Series at Hollywood Casino 400 to help raise awareness.
          </h4>

            <!--<v-btn
              color="white"
              dark
              x-large
              @click="clickNFT"
              class="primary--text mt-8"
              style="z-index: 200;"
            >
              VIEW NFT SWEEPSTAKES
            </v-btn>-->
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <img
            id="header-car"
            class="shake"
            v-if="!$vuetify.breakpoint.xsOnly"
            src="/img/header-car.png"
            style="margin-top: -450px;
              width: 650px;
              margin-left: 140px;
              position: absolute;"
          />
        </v-col>
      </v-row>
    </v-container>
    </v-parallax>




    <v-container class="my-04 py-8 text-center" >
      <v-col cols="12" md="12">
        <v-row align="center"
        justify="center">
          <v-col cols="12" md="5">
            <p class="caption text-uppercase ma-0 pa-0">HOLLYWOOD CASINO 400</p>
            <h3 class="">Kansas Speedway | 267 Laps</h3>
          </v-col>
          <v-col cols="12" md="2">
            <img src="/img/hollywood.png" style="width: 180px;"
            />
          </v-col>
          <v-col cols="12" md="5">
            <p class="caption text-uppercase ma-0 pa-0">SEPTEMBER 11 | 2:00 CST</p>
            <h3>Kansas City, KS</h3>
          </v-col>
        </v-row>
      </v-col>
    </v-container>





    <div id="team"></div>

    <v-parallax dark src="/img/mobile-noah.png" height="1200" contain v-if="$vuetify.breakpoint.smAndDown">
      <v-container>
      <v-row>
        <v-col cols="12" md="6" offset-md="6">
          <img
            src="/img/mobile-16.png"
            width="90%"
            style="max-width:500px;"
          />
          <h2 class="font-weight-thin my-0 py-0 mt-10" style="line-height: 1em;">THE DRIVER</h2>
          <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3em; line-height: .8em">
            NOAH GRAGSON
          </h1>
          <p class="">HOMETOWN: Las Vegas, NV<br>BORN: July 15, 1998</p>
          <p class="">Noah Gragson, the 23-year-old from Las Vegas, NV has quickly established himself as one of the brightest rising stars in NASCAR. In just his second full-time season, Noah finished second in the NASCAR Gander Outdoor Truck Series Driver Championship. An alumni of the NASCAR Next class – which recognizes the future virtuosos in the sport – Noah has also made a name for himself off-track with his outgoing personality, media involvement and positive nature.
          </p>
          <v-btn
            x-large
            color="primary"
            class="white--text mt-4 mr-6"
            href="https://noahgragsonracing.com/"
            target="_blank"
          >MEET NOAH GRAGSON</v-btn>
          <v-btn
            x-large
            color="grey"
            class="white--text mt-4"
            href="https://www.kauligracing.com/"
            target="_blank"
          >KAULIG RACING</v-btn>
        </v-col>
      </v-row>
    </v-container>
    </v-parallax>







    <v-parallax dark src="/img/noah.png" height="800" v-if="!$vuetify.breakpoint.smAndDown">
      <v-container>
      <v-row>
        <v-col cols="12" sm="6" offset-sm="6">
          <h2 class="font-weight-thin my-0 py-0" style="line-height: 1em;">THE DRIVER</h2>
          <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3em; line-height: .8em">
            NOAH GRAGSON
          </h1>
          <p class="">HOMETOWN: Las Vegas, NV<br>BORN: July 15, 1998</p>
          <p class="">Noah Gragson, the 23-year-old from Las Vegas, NV has quickly found himself as one of the brightest young talents in NASCAR. In just his second full-time season, Noah finished second in the NASCAR Gander Outdoor Truck Series driver championship.

An alumni of the NASCAR Next class, which recognizes the future star drivers in the sport, Noah has since made a name for himself off the track as well with his outgoing personality, media involvement and positive nature.
          </p>
          <v-btn
            x-large
            color="primary"
            class="white--text mt-4 mr-6"
            href="https://noahgragsonracing.com/"
            target="_blank"
          >MEET NOAH GRAGSON</v-btn>
          <v-btn
            x-large
            color="grey"
            class="white--text mt-4"
            href="https://www.kauligracing.com/"
            target="_blank"
          >KAULIG RACING</v-btn>
        </v-col>
      </v-row>
    </v-container>
    </v-parallax>



    <div id="cure"></div>

    <v-parallax
      dark
      src="/img/cure_background.png"
      height="1000"
    >
      <v-container>
      <v-row align="center"
      justify="center">
        <v-col
          cols="12"
          sm="6"
        >
          <h2 class="font-weight-thin my-0 py-0" style="line-height: 1em;">WHAT IS</h2>
          <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.5em; line-height: .8em">
            CURE TOKEN
          </h1>
          <p class="">
            CURE is dedicated to using the power of decentralized finance to help rid the planet of dreadful diseases like cancer. Partnering with global research teams and drawing from a wealth of experience in the innovation, technological, and charitable sectors, CURE aims to go one step further than simply becoming the world's most successful charity token.
          </p>
          <v-btn
            x-large
            color="white"
            class="primary--text mt-4 mr-6"
            href="https://www.curetoken.net"
            target="_blank"
          >LEARN MORE</v-btn>
          <v-btn
            x-large
            color="white"
            class="primary--text mt-4"
            @click="buyWidget = true"
          >BUY WITH CURE PAY</v-btn>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          align="center"
          justify="center"
        >
          <v-img src="/img/cure-image.png" eager style="background-color: transparent !important;"></v-img>
        </v-col>


      </v-row>
    </v-container>
    </v-parallax>



    <div id="foundation"></div>

    <v-parallax
      dark
      src="/img/foundation-background.png"
      height="1000"
    >
    <v-container>
      <v-row align="center"
      justify="center">
        <v-col
          cols="12"
          sm="6"
        >
          <h2 class="font-weight-thin my-0 py-0" style="line-height: 1em;">THE BECKLEY FOUNDATION</h2>
          <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.5em; line-height: .8em;">
            OUR MISSION
          </h1>

          <p class="">
            The Beckley Foundation, for pediatric cancer, is a 501c3 non-profit, philanthropic endeavor on the part of committed individuals from all walks of life bound together by the sincere desire to eradicate childhood cancer.
          </p>
          <p class="">
Talent from the medical, scientific and business communities join forces on an entirely voluntary basis to create awareness, identify promising initiatives and support research into cures for the most virulent and underfunded forms of childhood cancer. Growth of the Beckley Foundation is accomplished through personal and organizational giving, as well as through numerous fund-raising events.
          </p>
          <v-btn
            x-large
            color="white"
            class="black--text mt-4"
            href="https://thebeckleyfoundation.org/"
            target="_blank"
          >Visit the Beckley Foundation</v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          align="center"
          justify="center"
        >
          <v-img src="/img/foundation-image.png" eager style="background-color: transparent !important;"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-parallax>

    




    


    




<v-parallax
      dark
      src="/img/light_background.png"
      height="800"
      id="logos"
    >
    <v-container class="d-flex" style="min-height: 500px;">
      <v-row>
        <v-col cols="12" sm="3" class="full-height my-auto" >
          <a href="https://noahgragsonracing.com/" target="_blank">
            <v-img src="/img/01.png" aspect-ratio="1.5" class="grey lighten-2" width="200" style="margin: auto; background-color: transparent !important;">
            </v-img>
          </a>
        </v-col>
        <v-col cols="12" sm="3" class="full-height my-auto" >
          <a href="https://www.kauligracing.com/" target="_blank">
          <v-img src="/img/02.png" aspect-ratio="1.5" class="grey lighten-2" width="200" style="margin: auto; background-color: transparent !important;">
          </v-img>
          </a>
        </v-col>
        <v-col cols="12" sm="3" class="full-height my-auto">
          <a href="https://www.curetoken.net/" target="_blank">
          <v-img src="/img/03.png" aspect-ratio="1.5" class="grey lighten-2" width="200" style="margin: auto; background-color: transparent !important;">
          </v-img>
          </a>
        </v-col>
        <v-col cols="12" sm="3" class="full-height my-auto">
          <a href="https://thebeckleyfoundation.org/" target="_blank">
          <v-img src="/img/04.png" aspect-ratio="1.5" class="grey lighten-2" width="200" style="margin: auto; background-color: transparent !important;">
          </v-img>
          </a>
        </v-col>
      </v-row>
    </v-container>
</v-parallax>


    <div id="sweeps"></div>

    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-parallax dark src="/img/mobile-sweep.png" height="1200" >
      <v-container style="height: 700px;">
        <v-row>
          <v-col cols="12" md="6">
            <h2 class="font-weight-thin my-0 py-0" style="line-height: 1em;">CURE PAY TO PLAY</h2>
            <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3em; line-height: .8em;">
              AMAZING PRIZES
            </h1>
            <h3 class="mb-4">To celebrate the upcoming race with partners NASCAR on September 11th we are giving away incredible prizes! </h3>
            <p>Want to go to NASCAR yourself? Well now you can if you are our lucky top winner, as you’ll be given two tickets to next year’s race! </p>
            <p>Come second place and you’ll be able to watch the races on your shiny new iPhone! </p>
            <p>Third place and you’ll become an official holder of CURE, with $250 dollars of CURE Tokens, sent directly to your personal electronic wallet!</p>
          </v-col>
          <v-col cols="12" md="6" >
            <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.25em; line-height: .8em">
            HOW TO PLAY
          </h1>
          <p class="mt-4">Buy over $100 of CURE using our sleek and streamlined CURE PAY platform. The top 3 winners will be randomly selected on the 14th September and announced on Twitter!</p> 
          <h3 class="mt-4">Join us as CURE as we RACE4ACURE!</h3>
            <v-btn
              x-large
              color="white"
              class="primary--text mt-4"
              @click="buyWidget = true"
            >BUY WITH CURE PAY</v-btn>
          </v-col>
          <v-col cols="12" md="7" class="my-auto" >
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>
    <v-img
        src="/img/3d-car.png"
        class="grey lighten-2"
        style="margin: auto; background-color: transparent !important; position: absolute;
        margin: -550px auto auto 120px;
        width: 1350px;"
        id="sweepstakes-car"
    ></v-img>
  </div>


  <div v-if="!$vuetify.breakpoint.smAndDown">
    <v-parallax dark src="/img/black.png" height="900" id="sweepstakes" >
      <v-container style="height: 600px;">
        <v-row>
          <v-col cols="12" md="7" class="pr-12">
            <h2 class="font-weight-thin my-0 py-0" style="line-height: 0em;">CURE PAY TO PLAY</h2>
            <h1 class="font-weight-regular my-0 py-0" style="font-size: 3em;">
              AMAZING PRIZES
            </h1>
            <h3 class="mb-4">To celebrate the upcoming race with partners NASCAR on September 11th we are giving away incredible prizes! </h3>
            <p>Want to go to NASCAR yourself? Well now you can if you are our lucky top winner, as you’ll be given two tickets to next year’s race! </p>
            <p>Come second place and you’ll be able to watch the races on your shiny new iPhone! </p>
            <p>Third place and you’ll become an official holder of CURE, with $250 dollars of CURE Tokens, sent directly to your personal electronic wallet!</p>
          </v-col>
          <v-col cols="12" md="4" align="center" justify="center" >
          <h1 class="font-weight-regular my-0 py-0 mb-4" style="font-size: 3.25em; line-height: .8em">
            HOW TO PLAY
          </h1>
          <p class="mt-4">Buy over $100 of CURE using our sleek and streamlined CURE PAY platform. The top 3 winners will be randomly selected on the 14th September and annoucn ed on Twitter!</p> 
          <h3 class="mt-4">Join us as CURE as we RACE4ACURE!</h3>
            <v-btn
              x-large
              color="white"
              class="primary--text mt-4"
              @click="buyWidget = true"
            >BUY WITH CURE PAY</v-btn>
          </v-col>
          <v-col cols="12" md="7" class="my-auto" >
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>
    <v-img
        src="/img/3d-car.png"
        class="grey lighten-2"
        style="margin: auto; background-color: transparent !important; position: absolute;
        margin: -450px auto auto 120px;
        width: 1350px;"
        id="sweepstakes-car"
    ></v-img>
      </div>




      <v-sheet
      height="200px"
      color="grey darken-4"
    >

    </v-sheet>

    





    
  </v-container>
</template>
<style>
  h3{
    
  }

  .flip-card__top{
    color:#e14d28 !important;
  }

  .flip-card__bottom, .flip-card__back-bottom{
    color:#f48226 !important;
  }

  .flip-card__back-bottom{
    color:#f48226 !important;
  }

  .flip-card__back::before, .flip-card__back-bottom::after{
    color:#ff9f00 !important;
  }

  .flip-clock__slot{
    text-transform: uppercase;
    font-size: .8rem !important;
  }

  @media only screen and (max-width: 950px) {
    #header-car {
      margin-left: -50px !important;
    }
    #header-parallax {
      
    }
    #logos{
    }

    #sweepstakes-car{
      margin-left:0px !important;
    }
  }

  @media only screen and (max-width: 1250px) {

    #sweepstakes-car{
      margin-left:0px !important;
      margin-top: -170px !important;

    }
  }


  .shake {
    animation: shake 5s cubic-bezier(.17,.67,.91,.53) both infinite;
    transform: translate3d(0, 0, 0);

  }

  @keyframes shake {
    1%, 5%, 10%, 15%, 20%, 25%, 30%, 35% {
      transform: translate3d(-1px, -1px, 0);
    }
    3%, 7%, 12%, 17%, 21%, 24%, 27%, 31% {
      transform: translate3d(1px, 1px, 0);
    }
    30%, 50% {
      transform: translate3d(-1px, -2px, 0);
    }
    100% {
      transform: translate3d(-1px, -2px, 0);
    }
  }

</style>

<script>
  //import $ from 'jquery'

  export default {
    name: 'Homepage',
    components: {  },
    data: () => ({
      buyWidget: false,
      promotion: false,
    }),
    methods: {
      clickNFT() {
        this.$router.push('/nft');
      },
      iframeEvent(event) {
        let iframe = document.querySelector('#widget');
        if(event.origin != "https://www.curepayapp.com") return;
        iframe.height = event.data.height;
      }
    },
    mounted() {
      window.addEventListener("message", this.iframeEvent, false);
      //window.addEventListener("message", (event) => {
        //let iframe = document.querySelector('#widget');
        //console.log(iframe)
        //console.log(iframe.contentWindow)
        //console.log(event.origin)
        //console.log(event)
        //console.log(event.data)
        //console.log(event.data.height)
        //iframe.height = event.data.height;
      //});
    }
  }
</script>


